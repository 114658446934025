import moment from 'moment-timezone';
import LicenceGroup from 'Models/Licence/Data/LicenceGroup';
import {
   CERTIFYING_LICENCE_TYPES,
   TRADESMAN_LICENCE_TYPES,
   JOURNEYMAN_LICENCE_TYPES,
   EXEMPT_LICENCE_TYPES,
   TRAINEE_LICENCE_TYPES,
   PLUMBER_LICENCE_TYPES,
   DRAINLAYER_LICENCE_TYPES,
   GASFITTER_LICENCE_TYPES,
} from 'Util/Constants/LicenceTypes';

export const checkLicences = (userLicence: LicenceGroup): boolean => {
   return !!userLicence.currentLicences;
};

export const getYearRange = (date: string): string => {
   const startYear = moment(date)
      .subtract(1, 'years')
      .year();
   const endYear = moment(date).format('YY');
   return startYear + '-' + endYear;
};

export const getLicenceLevel = (licenceTypeCode: string): string => {
   const trimmed = licenceTypeCode;
   if (CERTIFYING_LICENCE_TYPES.includes(trimmed)) return 'Certifying';
   if (TRADESMAN_LICENCE_TYPES.includes(trimmed)) return '';
   if (JOURNEYMAN_LICENCE_TYPES.includes(trimmed)) return 'Restricted';
   if (EXEMPT_LICENCE_TYPES.includes(trimmed)) return 'Exempt';
   if (TRAINEE_LICENCE_TYPES.includes(trimmed)) return 'Trainee';
   return 'Unknown';
};

export const getLicenceDiscipline = (licenceTypeCode: string): string => {
   const trimmed = licenceTypeCode;
   if (PLUMBER_LICENCE_TYPES.includes(trimmed)) return 'Plumber';
   if (DRAINLAYER_LICENCE_TYPES.includes(trimmed)) return 'Drainlayer';
   if (GASFITTER_LICENCE_TYPES.includes(trimmed)) return 'Gasfitter';
   return 'Unknown';
};
