import React, { useState, useCallback } from 'react';
import { Column, SortingRule } from 'react-table';
import { TableRowLink } from 'Components/Common/TableRowLink/TableRowLink';
import { useSelector, useDispatch } from 'react-redux';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import TextField from 'react-md/lib/TextFields';
import DialogContainer from 'react-md/lib/Dialogs';
import Button from 'react-md/lib/Buttons';
import { selectIsSuccess } from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import RegistrationTypeReadonly, {
   validateRegistrationType,
} from 'Models/RegistrationType/Dto/RegistrationType';
import {
   RegistrationTypeTypes,
   RegistrationTypeActions,
} from 'State/Redux/RegistrationTypeRedux';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import SelectField from 'react-md/lib/SelectFields';
import { DISCIPLINE_CODES } from 'Util/Constants/RegistrationTypes';

interface ResultsPanelProps {
   searchResults: readonly RegistrationTypeReadonly[];
   isLoading: boolean;
}

const RegistrationTypeResultsPanel = ({
   searchResults,
   isLoading,
}: Readonly<ResultsPanelProps>): JSX.Element => {
   const dispatch = useDispatch();
   const [
      editTarget,
      setEditTarget,
   ] = useState<RegistrationTypeReadonly | null>(null);
   const isSuccess = useSelector(
      selectIsSuccess([RegistrationTypeTypes.ADD_REGISTRATION_TYPE_REQUEST])
   );

   const [editCode, setEditCode] = useState('');
   const [editCodeNew, setEditCodeNew] = useState('');
   const [editDescription, setEditDescription] = useState('');
   const [editValue, setEditValue] = useState('');
   const [editDisciplineCodeValue, setEditDisciplineCodeValue] = useState('');
   const [showModal, setShowModal] = useState(false);
   const [updateClicked, setUpdateClicked] = useState(false);

   const setEditCodeCallback = useCallback((value: string) => {
      setEditCode(value);
   }, []);
   const setEditCodeNewCallback = useCallback((value: string) => {
      setEditCodeNew(value);
   }, []);
   const setEditDescriptionCallback = useCallback((value: string) => {
      setEditDescription(value);
   }, []);
   const setEditDisciplineCodeCallback = useCallback((value: string) => {
      setEditDisciplineCodeValue(value);
   }, []);
   const setEditValueCallback = useCallback((value: string) => {
      setEditValue(value);
   }, []);
   const updateShowModal = useCallback((showModal: boolean): void => {
      setShowModal(showModal);
   }, []);

   if (updateClicked && isSuccess !== null) {
      toast.success(`Registration Type updated successfully`);
      setUpdateClicked(false);
   }
   const isValidForm = editTarget
      ? Object.values(
           validateRegistrationType({
              ...editTarget,
              registrationTypeCode: editCode,
              description: editDescription,
              cblcredits: editValue,
              disciplineCode: editDisciplineCodeValue,
           })
        ).every((v): boolean => !!v)
      : false;

   const DEFAULT_SORTING: SortingRule[] = [
      { id: 'registrationTypeCode', desc: false },
   ];

   const COLUMN_HEADERS: Column<RegistrationTypeReadonly>[] = [
      {
         Header: 'Type Code',
         accessor: 'registrationTypeCode',
      },
      {
         Header: 'New Type Code',
         accessor: 'registrationTypeCodeNew',
      },
      {
         Header: 'Description',
         accessor: 'description',
      },
      {
         Header: 'Discipline Code',
         accessor: 'disciplineCode',
      },
      {
         Header: 'CBL Credits',
         accessor: 'cblcredits',
      },
      {
         Header: (): JSX.Element => {
            return <FaIcon icon="ellipsis-h" />;
         },
         id: 'edit',
         width: 50,
         accessor: FN_EMPTY_VOID,
         Cell: (cellInfo): JSX.Element => {
            return (
               <FaIcon
                  onClick={(): void => {
                     updateShowModal(true);
                     setEditTarget(cellInfo.original);
                     setEditCode(cellInfo.original.registrationTypeCode);
                     setEditCodeNew(cellInfo.original.registrationTypeCodeNew);
                     setEditDescription(cellInfo.original.description);
                     setEditValue(cellInfo.original.cblcredits);
                     setEditDisciplineCodeValue(
                        cellInfo.original.disciplineCode
                     );
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     updateShowModal(true);
                     setEditTarget(cellInfo.original);
                     setEditCode(cellInfo.original.registrationTypeCode);
                     setEditCodeNew(cellInfo.original.registrationTypeCodeNew);
                     setEditDescription(cellInfo.original.description);
                     setEditValue(cellInfo.original.cblcredits);
                     setEditDisciplineCodeValue(
                        cellInfo.original.disciplineCode
                     );
                  }}
                  className="small-icon md-pointer--hover"
                  icon="edit"
               />
            );
         },
         sortable: false,
      },
   ];

   return (
      <>
         <DialogContainer
            id="simple-list-dialog"
            visible={showModal}
            className={'edit-dialog'}
            title="Edit Registration Type"
            onHide={(): void => {
               updateShowModal(false);
            }}
            width={600}
            portal
         >
            <div className="md-grid md-cell--12 system-parameter-modal">
               <div className="md-grid md-cell--6 grey-background form-section">
                  <h3 className="md-cell md-cell--6">Type Code</h3>
                  <TextField
                     floating
                     id="type-code"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editCode}
                     required
                     placeholder="Type Code"
                     onChange={(value): void => {
                        setEditCodeCallback(value.toString());
                     }}
                     maxLength={4}
                     disabled
                  />
               </div>
               <div className="md-grid md-cell--6 grey-background form-section">
                  <h3 className="md-cell md-cell--6">New Type Code</h3>
                  <TextField
                     floating
                     id="type-code"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editCodeNew}
                     required
                     placeholder=""
                     onChange={(value): void => {
                        setEditCodeNewCallback(value.toString());
                     }}
                     maxLength={4}
                     disabled
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Description</h3>
                  <TextField
                     floating
                     id="description"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editDescription}
                     required
                     placeholder="Description"
                     onChange={(value): void => {
                        setEditDescriptionCallback(value.toString());
                     }}
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Discipline Code</h3>
                  <SelectField
                     floating
                     id="discipline-code"
                     placeholder="Discipline Code"
                     className="md-cell md-cell--12"
                     position={SelectField.Positions.BELOW}
                     value={editDisciplineCodeValue}
                     onChange={(value): void => {
                        setEditDisciplineCodeCallback(value.toString());
                     }}
                     errorText="Select Discipline Code"
                     menuItems={DISCIPLINE_CODES}
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">CBL Credits</h3>
                  <TextField
                     floating
                     id="cbl-credits"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editValue}
                     required
                     placeholder="CBL Credits"
                     onChange={(value): void => {
                        setEditValueCallback(value.toString());
                     }}
                  />
               </div>
               <Button
                  disabled={!isValidForm}
                  onClick={(): void => {
                     updateShowModal(false);
                     if (editTarget) {
                        dispatch(
                           RegistrationTypeActions.updateRegistrationTypeRequest(
                              {
                                 ...editTarget,
                                 registrationTypeCode: editCode,
                                 description: editDescription,
                                 cblcredits: editValue,
                                 disciplineCode: editDisciplineCodeValue,
                              }
                           )
                        );
                     }
                     updateShowModal(false);
                     setEditTarget(null);
                     setUpdateClicked(true);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     if (editTarget) {
                        dispatch(
                           RegistrationTypeActions.updateRegistrationTypeRequest(
                              {
                                 ...editTarget,
                                 registrationTypeCode: editCode,
                                 description: editDescription,
                                 cblcredits: editValue,
                                 disciplineCode: editDisciplineCodeValue,
                              }
                           )
                        );
                     }
                     updateShowModal(false);
                     setEditTarget(null);
                     setUpdateClicked(true);
                  }}
                  flat
                  primary
                  swapTheming
               >
                  Save
               </Button>
               <Button
                  onClick={(): void => {
                     updateShowModal(false);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) updateShowModal(false);
                  }}
                  className="cancel-button"
                  flat
                  secondary
                  swapTheming
               >
                  Cancel
               </Button>
            </div>
         </DialogContainer>
         <ClientSideTable
            data={searchResults}
            defaultPageSize={10}
            columns={COLUMN_HEADERS}
            defaultSorted={DEFAULT_SORTING}
            loading={isLoading}
            TrComponent={TableRowLink}
         />
      </>
   );
};

export default RegistrationTypeResultsPanel;
