import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './ExamEligibilityPanel.scss';
import {
   selectOrderedAndGroupedExaminationEligibility,
   selectContactExamEligibilityOtherNotes,
   ExaminationTypes,
   ExaminationActions,
} from 'State/Redux/ExaminationRedux';
import ContactExamEligibilityDto from 'Models/Examination/Dto/ContactExamEligibilityDto';
import { selectContact } from 'State/Redux/ContactRedux';
import ExamEligibilityCard from './ExamEligibilityCard';
import { CourseActions, selectCourseCriteria } from 'State/Redux/CourseRedux';
import CourseCriteriaDto from 'Models/Examination/Dto/CourseCriteriaDto';
import ContactExamEligibilityOtherNotesDtoReadonly from 'Models/Examination/Data/ContactExamEligibilityOtherNotesDto';
import {
   TRADESMAN_DRAINLAYER_COURSE_NUMBER_STRING,
   TRADESMAN_GASFITTER_COURSE_NUMBER_STRING,
   TRADESMAN_PLUMBER_COURSE_NUMBER_STRING,
   CERTIFYING_PLUMBER_COURSE_NUMBER_STRING,
   CERTIFYING_GASFITTER_COURSE_NUMBER_STRING,
   CERTIFYING_DRAINLAYER_COURSE_NUMBER_STRING,
   CRITERIA_NUMBERS,
   CRITERIA_OTHER,
} from 'Util/Constants/Examination';
import Loading from 'Components/Common/Loading';
import { selectIsLoading, selectIsError } from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import LoadingButton from '../LoadingButton';
import DownloadReportButton from './DownloadReportButton';
import SendReportToAspeqButton from './SendReportToAspeqButton';
import SendReportToUserButton from './SendReportToUserButton';
import { isEnterKeyPress } from 'Util/Helpers/Input';

interface ExamEligibilityPanelProps {
   onClose: () => void;
}

export const ExamEligibilityPanel = ({
   onClose,
}: Readonly<ExamEligibilityPanelProps>): JSX.Element => {
   const contact = useSelector(selectContact);
   const examinationEligibility = useSelector(
      selectOrderedAndGroupedExaminationEligibility
   );
   const courseCriteria = useSelector(selectCourseCriteria);
   const otherNotes = useSelector(selectContactExamEligibilityOtherNotes);
   const isLoadingExaminationDetails = useSelector(
      selectIsLoading([
         ExaminationTypes.GET_EXAMINATION_ELIGIBILITY_BY_CONTACT_ID_REQUEST,
         ExaminationTypes.GET_EXAMINATION_ELIGIBILITY_OTHER_NOTES_BY_CONTACT_ID_REQUEST,
      ])
   );
   const isErrorExaminationDetails = useSelector(
      selectIsError([
         ExaminationTypes.GET_EXAMINATION_ELIGIBILITY_BY_CONTACT_ID_REQUEST,
         ExaminationTypes.GET_EXAMINATION_ELIGIBILITY_OTHER_NOTES_BY_CONTACT_ID_REQUEST,
      ])
   );
   const isSubmittingExaminationEligibility = useSelector(
      selectIsLoading([
         ExaminationTypes.UPDATE_CONTACT_EXAMINATION_ELIGIBILITY_REQUEST,
         ExaminationTypes.UPDATE_CONTACT_EXAMINATION_ELIGIBILITY_OTHER_NOTES_REQUEST,
      ])
   );

   const dispatch = useDispatch();

   const [isSaveTriggered, setIsSaveTriggered] = useState(false);
   const [loadingButtonSave, setLoadingButtonSave] = useState<boolean>(false);

   useEffect((): void => {
      // save successful
      if (isSaveTriggered && !isSubmittingExaminationEligibility) {
         setIsSaveTriggered(false);
         toast.success(`Updated Contact Exam Eligibility`);
         setLoadingButtonSave(false);
         onClose();
      }
   }, [isSaveTriggered, isSubmittingExaminationEligibility, onClose]);

   const loadExaminationDetails = (): void => {
      dispatch(
         ExaminationActions.getExaminationEligibilityByContactIdRequest(
            contact.contactId.toString()
         )
      );
      dispatch(
         ExaminationActions.getExaminationEligibilityOtherNotesByContactIdRequest(
            contact.contactId.toString()
         )
      );

      if (courseCriteria === null || courseCriteria.length === 0) {
         dispatch(CourseActions.getCourseCriteriaRequest());
      }
   };
   useEffect(loadExaminationDetails, [contact.contactId]);

   if (!examinationEligibility) return <></>;

   const reduceCourseCriteria = (
      courseNumber: string
   ): Readonly<CourseCriteriaDto[]> => {
      return courseCriteria.filter(
         (cc): boolean =>
            cc.course !== null && cc.course.courseNumber === courseNumber
      );
   };

   const validateAndSave = (): void => {
      const tempNotesList: ContactExamEligibilityOtherNotesDtoReadonly[] = [];
      const tempEligibilityList: ContactExamEligibilityDto[] = [];
      for (const key in examinationEligibility) {
         const otherEligibility = examinationEligibility[
            key as CRITERIA_NUMBERS
         ].find(
            (e): boolean => e.courseCriteria.displayText === CRITERIA_OTHER
         );
         const validationMessage =
            'Validation Failed: Other Description is required';
         if (otherEligibility && otherEligibility.isCriteriaMet && otherNotes) {
            const otherExamEligibilityId =
               otherEligibility.contactExamEligibilityId;
            const note = otherNotes.find(
               (note): boolean =>
                  note.contactExamEligibilityId === otherExamEligibilityId
            );
            if (
               !note ||
               (note.otherNotes !== null && note.otherNotes.trim() === '')
            ) {
               toast.error(validationMessage);
               dispatch(
                  ExaminationActions.updateContactExaminationEligibilityState({
                     ...otherEligibility,
                     isError: true,
                  })
               );
               return;
            } else {
               dispatch(
                  ExaminationActions.updateContactExaminationEligibilityState({
                     ...otherEligibility,
                     isError: false,
                  })
               );
               tempNotesList.push(note);
            }
         }
         tempEligibilityList.push(
            ...examinationEligibility[key as CRITERIA_NUMBERS]
         );
      }
      if (
         !tempEligibilityList.some(
            (eligibility): boolean =>
               eligibility.isError && eligibility.isCriteriaMet
         )
      ) {
         //if it gets this far then the notes validation succeeded
         dispatch(
            ExaminationActions.updateContactExaminationEligibilityRequest(
               contact.contactId.toString(),
               tempEligibilityList
            )
         );
         dispatch(
            ExaminationActions.updateContactExaminationEligibilityOtherNotesRequest(
               tempNotesList
            )
         );
         setIsSaveTriggered(true);
         setLoadingButtonSave(true);
      }
   };

   return (
      <Loading
         isLoading={
            isLoadingExaminationDetails || isSubmittingExaminationEligibility
         }
         isError={isErrorExaminationDetails}
         tryReload={loadExaminationDetails}
         overlayOnChildren
      >
         <>
            <div className="md-grid exam-criteria-panel">
               <ExamEligibilityCard
                  className="plumber-card"
                  title={`${CERTIFYING_PLUMBER_COURSE_NUMBER_STRING} Certifying Plumber`}
                  courseNumber={CERTIFYING_PLUMBER_COURSE_NUMBER_STRING}
                  courseCriteria={reduceCourseCriteria(
                     CERTIFYING_PLUMBER_COURSE_NUMBER_STRING
                  )}
                  otherNotes={otherNotes}
               />
               <ExamEligibilityCard
                  className="gasfitter-card"
                  title={`${CERTIFYING_GASFITTER_COURSE_NUMBER_STRING} Certifying Gasfitter`}
                  courseNumber={CERTIFYING_GASFITTER_COURSE_NUMBER_STRING}
                  courseCriteria={reduceCourseCriteria(
                     CERTIFYING_GASFITTER_COURSE_NUMBER_STRING
                  )}
                  otherNotes={otherNotes}
               />
               <ExamEligibilityCard
                  className="drainlayer-card"
                  title={`${CERTIFYING_DRAINLAYER_COURSE_NUMBER_STRING} Certifying Drainlayer`}
                  courseNumber={CERTIFYING_DRAINLAYER_COURSE_NUMBER_STRING}
                  courseCriteria={reduceCourseCriteria(
                     CERTIFYING_DRAINLAYER_COURSE_NUMBER_STRING
                  )}
                  otherNotes={otherNotes}
               />
            </div>
            <div className="md-grid exam-criteria-panel">
               <ExamEligibilityCard
                  className="plumber-card"
                  title={`${TRADESMAN_PLUMBER_COURSE_NUMBER_STRING} Plumber`}
                  courseNumber={TRADESMAN_PLUMBER_COURSE_NUMBER_STRING}
                  courseCriteria={reduceCourseCriteria(
                     TRADESMAN_PLUMBER_COURSE_NUMBER_STRING
                  )}
                  otherNotes={otherNotes}
               />
               <ExamEligibilityCard
                  className="gasfitter-card"
                  title={`${TRADESMAN_GASFITTER_COURSE_NUMBER_STRING} Gasfitter`}
                  courseNumber={TRADESMAN_GASFITTER_COURSE_NUMBER_STRING}
                  courseCriteria={reduceCourseCriteria(
                     TRADESMAN_GASFITTER_COURSE_NUMBER_STRING
                  )}
                  otherNotes={otherNotes}
               />
               <ExamEligibilityCard
                  className="drainlayer-card"
                  title={`${TRADESMAN_DRAINLAYER_COURSE_NUMBER_STRING} Drainlayer`}
                  courseNumber={TRADESMAN_DRAINLAYER_COURSE_NUMBER_STRING}
                  courseCriteria={reduceCourseCriteria(
                     TRADESMAN_DRAINLAYER_COURSE_NUMBER_STRING
                  )}
                  otherNotes={otherNotes}
               />
            </div>
            <div className="eligibility-buttons">
               <DownloadReportButton />
               <SendReportToUserButton />
               <SendReportToAspeqButton />
               <LoadingButton
                  flat
                  primary
                  swapTheming
                  className="save-button"
                  onClick={(): void => {
                     validateAndSave();
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     validateAndSave();
                  }}
                  isLoading={loadingButtonSave}
               >
                  Save
               </LoadingButton>
            </div>
         </>
      </Loading>
   );
};

export default ExamEligibilityPanel;
