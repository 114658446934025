import React from 'react';
import ContactSearchResult, {
   LicenceTypeDescriptionReadonly,
   RegistrationTypeDescriptionReadonly,
   ContactSearchContactReadonly,
} from 'Models/Contact/Data/ContactSearchResult';
import { Redirect } from 'react-router';
import { PagedQuery } from 'Models/Other/PagedQuery';
import { ServerSideTable } from 'Components/Common/ServerSideTable/ServerSideTable';
import { RowInfo, Column } from 'react-table';
import { useSelector } from 'react-redux';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { ContactTypes } from 'State/Redux/ContactRedux';
import { TableRowLink } from 'Components/Common/TableRowLink/TableRowLink';

const getContactUrl = (contact: ContactSearchContactReadonly): string => {
   return `/contact/${contact.contactId}`;
};

interface RegistrationCellProps {
   value: readonly RegistrationTypeDescriptionReadonly[];
}

const RegistrationTypeCell = ({
   value,
}: Readonly<RegistrationCellProps>): JSX.Element => {
   const registrationTypeAbbrs = value.map(
      (r): JSX.Element => (
         <abbr key={r.registrationTypeCodeNew} title={r.description}>
            {r.registrationTypeCodeNew}
         </abbr>
      )
   );
   return registrationTypeAbbrs.length > 0 ? (
      registrationTypeAbbrs.reduce(
         (prev, current): JSX.Element => <>{[prev, ', ', current]}</>
      )
   ) : (
      <></>
   );
};

interface LicenceCellProps {
   value: readonly LicenceTypeDescriptionReadonly[];
}

const LicenceTypeCell = ({
   value,
}: Readonly<LicenceCellProps>): JSX.Element => {
   const licenceTypeAbbrs = value.map(
      (l): JSX.Element => (
         <abbr key={l.licenceTypeCodeNew} title={l.description}>
            {l.licenceTypeCodeNew}
         </abbr>
      )
   );
   return licenceTypeAbbrs.length > 0 ? (
      licenceTypeAbbrs.reduce(
         (prev, current): JSX.Element => <>{[prev, ', ', current]}</>
      )
   ) : (
      <></>
   );
};

const COLUMN_HEADERS: Column<ContactSearchContactReadonly>[] = [
   {
      Header: 'Licence Number',
      accessor: 'registrationNumber',
   },
   {
      Header: 'Contact Name',
      accessor: 'fullName',
   },
   {
      Header: 'Company Name',
      accessor: 'companyName',
   },
   {
      Header: 'City',
      accessor: 'city',
   },
   {
      Header: 'Registration Type(s)',
      accessor: 'registrationTypes',
      Cell: RegistrationTypeCell,
      sortable: false,
   },
   {
      Header: 'Licence Type(s)',
      accessor: 'licenceTypes',
      Cell: LicenceTypeCell,
      sortable: false,
   },
   {
      Header: 'Gender',
      accessor: 'gender',
      sortable: false,
   },
   {
      Header: 'Ethnicity',
      accessor: 'ethnicity',
      sortable: false,
   },
];

interface ContactSearchResultsPanelProps {
   searchResults: ContactSearchResult | null;
   paging: PagedQuery;
   onPagingChanged: (paging: PagedQuery) => void;
}

const ContactSearchResultsPanel = ({
   searchResults,
   paging,
   onPagingChanged,
}: Readonly<ContactSearchResultsPanelProps>): JSX.Element => {
   const isLoading = useSelector(
      selectIsLoading([ContactTypes.SEARCH_CONTACT_REQUEST])
   );

   const totalCount =
      searchResults && searchResults.totalCount ? searchResults.totalCount : 0;
   const contacts =
      searchResults && searchResults.contacts ? searchResults.contacts : [];

   if (totalCount === 1)
      return <Redirect push to={getContactUrl(contacts[0])} />;

   return (
      <ServerSideTable
         headers={COLUMN_HEADERS}
         data={contacts}
         totalResults={totalCount}
         paging={paging}
         onPagingChanged={onPagingChanged}
         isLoading={isLoading}
         TrComponent={TableRowLink}
         getTrProps={(
            finalState: any, //eslint-disable-line
            rowInfo?: RowInfo
         ): object => {
            if (!rowInfo) return {};
            const contact =
               rowInfo && (rowInfo.original as ContactSearchContactReadonly);
            if (!contact) return {};
            if (contact.contactId <= 0) return {};
            const targetUrl = getContactUrl(contact);

            return {
               to: targetUrl,
               isExternal: true,
            };
         }}
      />
   );
};

export default ContactSearchResultsPanel;
