import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import { createScopedActions } from '.';
import QuestionDto from 'Models/FitAndProper/Dto/QuestionDto';
import ApiError from 'Models/Other/ApiError';
import StateReadonly from './StateModel';
import QuestionSaveDto from 'Models/FitAndProper/Dto/QuestionSaveDto';
import AnswerHistoryDto from 'Models/FitAndProper/Dto/AnswerHistoryDto';
import QuestionSaveBulkDto from 'Models/FitAndProper/Dto/QuestionSaveBulkDto';

/* ------------- Interfaces for ReduxSauce ------------- */
interface FitAndProperState {
   questionsAndAnswers: readonly QuestionDto[];
   registrationQuestionsAndAnswers: readonly QuestionDto[];
   answerHistory: readonly AnswerHistoryDto[];
   isFitToPractice: boolean | undefined;
   id: string | undefined;
}

export type FitAndProperStateReadonly = Readonly<FitAndProperState>;

interface TypeNames {
   GET_QUESTIONS_REQUEST: string;
   GET_QUESTIONS_SUCCESS: string;
   GET_QUESTIONS_FAILURE: string;
   GET_QUESTIONS_RESET: string;

   GET_QUESTIONS_AND_ANSWERS_BY_ID_REQUEST: string;
   GET_QUESTIONS_AND_ANSWERS_BY_ID_SUCCESS: string;
   GET_QUESTIONS_AND_ANSWERS_BY_ID_FAILURE: string;
   GET_QUESTIONS_AND_ANSWERS_BY_ID_RESET: string;

   GET_QUESTIONS_AND_ANSWERS_BY_ID_AND_QUESTION_ID_REQUEST: string;
   GET_QUESTIONS_AND_ANSWERS_BY_ID_AND_QUESTION_ID_SUCCESS: string;
   GET_QUESTIONS_AND_ANSWERS_BY_ID_AND_QUESTION_ID_FAILURE: string;
   GET_QUESTIONS_AND_ANSWERS_BY_ID_AND_QUESTION_ID_RESET: string;

   GET_ANSWER_HISTORY_BY_ID_REQUEST: string;
   GET_ANSWER_HISTORY_BY_ID_SUCCESS: string;
   GET_ANSWER_HISTORY_BY_ID_FAILURE: string;
   GET_ANSWER_HISTORY_BY_ID_RESET: string;

   SAVE_ANSWER_BY_ID_REQUEST: string;
   SAVE_ANSWER_BY_ID_SUCCESS: string;
   SAVE_ANSWER_BY_ID_FAILURE: string;
   SAVE_ANSWER_BY_ID_RESET: string;

   SAVE_ANSWER_LIST_BY_ID_REQUEST: string;
   SAVE_ANSWER_LIST_BY_ID_SUCCESS: string;
   SAVE_ANSWER_LIST_BY_ID_FAILURE: string;
   SAVE_ANSWER_LIST_BY_ID_RESET: string;

   SAVE_ANSWER_LIST_BY_ID_AND_QUESTION_ID_REQUEST: string;
   SAVE_ANSWER_LIST_BY_ID_AND_QUESTION_ID_SUCCESS: string;
   SAVE_ANSWER_LIST_BY_ID_AND_QUESTION_ID_FAILURE: string;
   SAVE_ANSWER_LIST_BY_ID_AND_QUESTION_ID_RESET: string;
}

type FitAndProperTypeNames = Readonly<TypeNames>;

export interface FitAndProperCreators {
   getQuestionsRequest: () => AnyAction;
   getQuestionsSuccess: (data: readonly QuestionDto[] | undefined) => AnyAction;
   getQuestionsFailure: (error: ApiError) => AnyAction;
   getQuestionsReset: () => AnyAction;

   getQuestionsAndAnswersByIdRequest: (id: string) => AnyAction;
   getQuestionsAndAnswersByIdSuccess: (
      data: readonly QuestionDto[] | undefined
   ) => AnyAction;
   getQuestionsAndAnswersByIdFailure: (error: ApiError) => AnyAction;
   getQuestionsAndAnswersByIdReset: () => AnyAction;

   getQuestionsAndAnswersByIdAndQuestionIdRequest: (
      userId: string,
      questionId: number,
      applicationId: number
   ) => AnyAction;
   getQuestionsAndAnswersByIdAndQuestionIdSuccess: (
      data: readonly QuestionDto[] | undefined
   ) => AnyAction;
   getQuestionsAndAnswersByIdAndQuestionIdFailure: (
      error: ApiError
   ) => AnyAction;
   getQuestionsAndAnswersByIdAndQuestionIdReset: () => AnyAction;

   getAnswerHistoryByIdRequest: (
      userId: string,
      questionId: number
   ) => AnyAction;
   getAnswerHistoryByIdSuccess: (
      data: readonly AnswerHistoryDto[] | undefined
   ) => AnyAction;
   getAnswerHistoryByIdFailure: (error: ApiError) => AnyAction;
   getAnswerHistoryByIdReset: () => AnyAction;

   saveAnswerByIdRequest: (question: QuestionSaveDto) => AnyAction;
   saveAnswerByIdSuccess: (data: boolean | undefined) => AnyAction;
   saveAnswerByIdFailure: (error: ApiError) => AnyAction;
   saveAnswerByIdReset: () => AnyAction;

   saveAnswerListByIdRequest: (
      userId: string,
      questions: QuestionSaveBulkDto[]
   ) => AnyAction;
   saveAnswerListByIdSuccess: (
      data: readonly QuestionDto[] | undefined
   ) => AnyAction;
   saveAnswerListByIdFailure: (error: ApiError) => AnyAction;
   saveAnswerListByIdReset: () => AnyAction;

   saveAnswerListByIdAndQuestionIdRequest: (
      userId: string,
      questions: QuestionSaveBulkDto[],
      questionId: number
   ) => AnyAction;
   saveAnswerListByIdAndQuestionIdSuccess: (
      data: readonly QuestionDto[] | undefined
   ) => AnyAction;
   saveAnswerListByIdAndQuestionIdFailure: (error: ApiError) => AnyAction;
   saveAnswerListByIdAndQuestionIdReset: () => AnyAction;
}

/* ------------- Initial State ------------- */

export const INITIAL_STATE: FitAndProperStateReadonly = {
   questionsAndAnswers: [] as QuestionDto[],
   registrationQuestionsAndAnswers: [] as QuestionDto[],
   answerHistory: [] as AnswerHistoryDto[],
   isFitToPractice: undefined,
   id: undefined,
};

/* ------------- Reducers ------------- */
const getQuestionsSuccess = (
   state: FitAndProperStateReadonly,
   action: AnyAction
): FitAndProperStateReadonly => {
   const questions = action.data as QuestionDto[];
   return {
      ...state,
      questionsAndAnswers: questions,
   };
};

const getQuestionsReset = (
   state: FitAndProperStateReadonly,
   action: AnyAction
): FitAndProperStateReadonly => {
   return { ...state, questionsAndAnswers: [] };
};

const getAnswerHistoryByIdSuccess = (
   state: FitAndProperStateReadonly,
   action: AnyAction
): FitAndProperStateReadonly => {
   const answerHistory = action.data as AnswerHistoryDto[];
   return { ...state, answerHistory: answerHistory };
};

const getAnswerHistoryByIdReset = (
   state: FitAndProperStateReadonly,
   action: AnyAction
): FitAndProperStateReadonly => {
   return { ...state, answerHistory: [] };
};

const getQuestionsAndAnswersByIdSuccess = (
   state: FitAndProperStateReadonly,
   action: AnyAction
): FitAndProperStateReadonly => {
   const questions = action.data as QuestionDto[];
   return {
      ...state,
      questionsAndAnswers: questions,
      isFitToPractice: questions.some(
         x => x.latestAnswer === undefined || x.latestAnswer === null
      )
         ? false
         : true,
   };
};

const getQuestionsAndAnswersByIdReset = (
   state: FitAndProperStateReadonly,
   action: AnyAction
): FitAndProperStateReadonly => {
   return {
      ...state,
      questionsAndAnswers: [],
      isFitToPractice: undefined,
      id: undefined,
   };
};

const getQuestionsAndAnswersByIdAndQuestionIdSuccess = (
   state: FitAndProperStateReadonly,
   action: AnyAction
): FitAndProperStateReadonly => {
   const questions = action.data as QuestionDto[];
   return {
      ...state,
      registrationQuestionsAndAnswers: questions,
      isFitToPractice: questions.some(
         x => x.latestAnswer === undefined || x.latestAnswer === null
      )
         ? false
         : true,
   };
};

const getQuestionsAndAnswersByIdAndQuestionIdReset = (
   state: FitAndProperStateReadonly,
   action: AnyAction
): FitAndProperStateReadonly => {
   return {
      ...state,
      registrationQuestionsAndAnswers: [],
      isFitToPractice: undefined,
      id: undefined,
   };
};

const saveAnswerByIdSuccess = (
   state: FitAndProperStateReadonly,
   action: AnyAction
): FitAndProperStateReadonly => {
   return {
      ...state,
   };
};
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createScopedActions<
   FitAndProperTypeNames,
   FitAndProperCreators
>('fitAndProper', {
   getQuestionsRequest: [],
   getQuestionsSuccess: ['data'],
   getQuestionsFailure: ['error'],
   getQuestionsReset: [],

   getQuestionsAndAnswersByIdRequest: ['id'],
   getQuestionsAndAnswersByIdSuccess: ['data'],
   getQuestionsAndAnswersByIdFailure: ['error'],
   getQuestionsAndAnswersByIdReset: [],

   getQuestionsAndAnswersByIdAndQuestionIdRequest: [
      'userId',
      'questionId',
      'applicationId',
   ],
   getQuestionsAndAnswersByIdAndQuestionIdSuccess: ['data'],
   getQuestionsAndAnswersByIdAndQuestionIdFailure: ['error'],
   getQuestionsAndAnswersByIdAndQuestionIdReset: [],

   getAnswerHistoryByIdRequest: ['userId', 'questionId'],
   getAnswerHistoryByIdSuccess: ['data'],
   getAnswerHistoryByIdFailure: ['error'],
   getAnswerHistoryByIdReset: [],

   saveAnswerByIdRequest: ['question'],
   saveAnswerByIdSuccess: ['data'],
   saveAnswerByIdFailure: ['error'],
   saveAnswerByIdReset: [],

   saveAnswerListByIdRequest: ['userId', 'questions'],
   saveAnswerListByIdSuccess: ['data'],
   saveAnswerListByIdFailure: ['error'],
   saveAnswerListByIdReset: [],

   saveAnswerListByIdAndQuestionIdRequest: [
      'userId',
      'questions',
      'questionId',
   ],
   saveAnswerListByIdAndQuestionIdSuccess: ['data'],
   saveAnswerListByIdAndQuestionIdFailure: ['error'],
   saveAnswerListByIdAndQuestionIdReset: [],
});

export const FitAndProperTypes = Types;
export const FitAndProperActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_QUESTIONS_SUCCESS]: getQuestionsSuccess,
   [Types.GET_QUESTIONS_RESET]: getQuestionsReset,
   [Types.GET_QUESTIONS_AND_ANSWERS_BY_ID_SUCCESS]: getQuestionsAndAnswersByIdSuccess,
   [Types.GET_QUESTIONS_AND_ANSWERS_BY_ID_RESET]: getQuestionsAndAnswersByIdReset,

   [Types.GET_QUESTIONS_AND_ANSWERS_BY_ID_AND_QUESTION_ID_SUCCESS]: getQuestionsAndAnswersByIdAndQuestionIdSuccess,
   [Types.GET_QUESTIONS_AND_ANSWERS_BY_ID_AND_QUESTION_ID_RESET]: getQuestionsAndAnswersByIdAndQuestionIdReset,

   [Types.GET_ANSWER_HISTORY_BY_ID_SUCCESS]: getAnswerHistoryByIdSuccess,
   [Types.GET_ANSWER_HISTORY_BY_ID_RESET]: getAnswerHistoryByIdReset,
   [Types.SAVE_ANSWER_BY_ID_SUCCESS]: saveAnswerByIdSuccess,
   [Types.SAVE_ANSWER_LIST_BY_ID_SUCCESS]: getQuestionsAndAnswersByIdSuccess,
   [Types.SAVE_ANSWER_LIST_BY_ID_RESET]: getQuestionsAndAnswersByIdReset,

   [Types.SAVE_ANSWER_LIST_BY_ID_AND_QUESTION_ID_SUCCESS]: saveAnswerByIdSuccess,
});

/* ------------- Selectors ------------- */

export const selectFitAndProper = (
   state: StateReadonly
): Readonly<QuestionDto[]> => {
   return state.fitAndProper.questionsAndAnswers;
};

export const selectRegistrationFitAndProper = (
   state: StateReadonly
): Readonly<QuestionDto[]> => {
   return state.fitAndProper.registrationQuestionsAndAnswers;
};

export const selectFitAndProperHistory = (
   state: StateReadonly
): Readonly<AnswerHistoryDto[]> => {
   return state.fitAndProper.answerHistory;
};

export const selectIsFitAndProper = (
   state: StateReadonly
): boolean | undefined => {
   return state.fitAndProper.isFitToPractice;
};

// export const isFetchingQA = (state: StateReadonly) =>
//  isBusy(REDUCER_NAME, ASYNC_GET)(state);

// export const isFetchingQASuccess = isSuccess(REDUCER_NAME, ASYNC_GET);

// export const isSaveQASuccess = (actionName: string, state: any) =>
//   isSuccess(REDUCER_NAME, actionName)(state);

// export const isFetchingQAFailure = getError(REDUCER_NAME, ASYNC_GET);
// export const getFetchError = getError(REDUCER_NAME, ASYNC_GET);
// export const getQAsData = (state: State) => state.fitAndProper;
